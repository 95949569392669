// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBe_vZvtQ8bnbCMy-3h74UER80-GM-luoo",
  authDomain: "lsae-86346.firebaseapp.com",
  projectId: "lsae-86346",
  storageBucket: "lsae-86346.appspot.com",
  messagingSenderId: "541698071486",
  appId: "1:541698071486:web:5650fe0aaba42b5b7cb7ef"
};

export const projectName = "L'SAE"

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);