import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import './assets/css/webflow.css'
import './assets/css/normalize.css'
import './assets/css/login.css'
import './assets/css/macms.webflow.css'

import reportWebVitals from './reportWebVitals';
import RouterComponent from './pages/Router';
import { configureStore } from './state/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
export const { store, persistor } = configureStore({});

root.render(
  
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ReduxToastr
          newestOnTop={false}
          preventDuplicates
          position="bottom-right"
          getState={state => state.toastr}
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <RouterComponent />
      </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
