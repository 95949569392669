export function capitalizeFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function dateFormat(date) {
  const lastUpdatedDate = date ? new Date(date.seconds * 1000 ) : null;
  const options = { 
      day: 'numeric', 
      month: 'numeric', 
      year: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric', 
      timeZoneName: 'short' 
    };
  // Format the Date object into a human-readable string
  const formattedLastUpdated = lastUpdatedDate ? lastUpdatedDate.toLocaleString('en-GB', options) : "Not available";    
  return formattedLastUpdated
}
