import { useDispatch } from 'react-redux'
import { setMobileMenu } from '../../state/actions/mobileMenu'
import './styles.css'

function SettingsPage () {
    const dispatch = useDispatch()
    return (
        <>
            <div id="w-node-_71d3e7d6-5fd2-17fb-1098-41af6bfa4bc7-4a6ee746" class="menu-projects">
                <div class="settings-wrapper">
                    <div class="projects__heading">
                    <div class="projects-head__flex">
                        <h1 data-w-id="71d3e7d6-5fd2-17fb-1098-41af6bfa4bcb" class="h-24 text-dark">Settings</h1><img src="images/settings_1settings.jpg" loading="lazy" alt="" class="image"/>
                    </div>
                    <div onClick={()=> dispatch(setMobileMenu(true))} data-w-id="51606c7f-6930-90a3-4b4f-a26b00d797ae" style={{opacity:1,display:"flex"}} class="hamburger-wrap _3-lines">
                        <div class="hamburger-line"></div>
                        <div class="hamburger-line middle"></div>
                        <div class="hamburger-line"></div>
                    </div>
                    </div>
                    <div class="border mt-16"></div>
                    <div class="settings-wrap">
                    <div class="name-form">
                        <h2 class="h-20 text-dark mb-14">Name</h2>
                        <div class="no-m w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get" data-ms-form="profile" class="form-flex vertical" data-wf-page-id="649d9a14bfbc7dd44a6ee746" data-wf-element-id="c6b9280a-4cec-39d7-fa14-e18eb90726ac">
                            <div class="form-field-wrap">
                                <label for="First-Name" class="form-label">First Name</label>
                                <input type="text" class="form-field w-input" maxLength="256" name="First-Name" data-name="First Name" placeholder="Foo" id="First-Name" data-ms-member="first-name"/>
                            </div>
                            <div class="spacer-xs"></div>
                            <div class="form-field-wrap"><label for="Last-Name-2" class="form-label">Last Name</label><input type="text" class="form-field w-input" maxLength="256" name="Last-Name" data-name="Last Name" placeholder="Bar" id="Last-Name-2" data-ms-member="last-name"/></div>
                            <input type="submit" value="Update Profile Settings" data-wait="Please wait..." class="submit-button w-button"/>
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                        </div>
                    </div>
                    <div class="email-form">
                        <h2 class="h-20 text-dark mb-14">Email</h2>
                        <div class="no-m w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get" data-ms-form="email" class="form-flex vertical" data-wf-page-id="649d9a14bfbc7dd44a6ee746" data-wf-element-id="2a9b8843-96dc-29f3-0597-b919a6e5391c">
                            <div class="form-field-wrap"><label for="name-2" class="form-label">Email Address</label><input type="email" class="form-field w-input" maxLength="256" name="name-2" data-name="Name 2" placeholder="foo@example.com" id="name-2" data-ms-member="email"/></div><input type="submit" value="Update Profile Settings" data-wait="Please wait..." class="submit-button w-button"/>
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                        </div>
                    </div>
                    <div class="password-form">
                        <h2 class="h-20 text-dark mb-14">Security</h2>
                        <div class="no-m w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get" data-ms-form="password" class="form-wrap" data-wf-page-id="649d9a14bfbc7dd44a6ee746" data-wf-element-id="4818c564-a628-39d8-8016-34bf673b6acb">
                            <div class="form-flex password">
                            <div class="form-field-wrap"><label for="name-3" class="form-label">Current Password</label><input type="text" class="form-field w-input" maxLength="256" name="name-3" data-name="Name 3" placeholder="Password" id="name-3" data-ms-member="current-password"/></div>
                            <div class="form-field-wrap"><label for="name-2" class="form-label">New Password</label><input type="text" class="form-field w-input" maxLength="256" name="name-2" data-name="Name 2" placeholder="New Password" id="name-2" data-ms-member="new-password"/></div>
                            </div><input type="submit" value="Update Profile Settings" data-wait="Please wait..." class="submit-button w-button"/>
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>    
    )
} 

export default SettingsPage