import { createReducer } from 'redux-act';

import { UPDATE_COLLECTION, SAVE_UNSUBSCRIBE, CLEAR_COLLECTIONS } from '../../actions/collections';

const initialState = {
  collections: {},
  unsubscribeFunctions: {},
};

// Define the reducers
export const collectionsReducer = createReducer(
  {
    [UPDATE_COLLECTION]: (state, payload) => {
      const { collectionName, data } = payload;
      return {
        ...state,
        collections: {
          ...state.collections,
          [collectionName]: data,
        },
      };
    },
    [SAVE_UNSUBSCRIBE]: (state, payload) => {
      const { collectionName, unsubscribe } = payload;
      return {
        ...state,
        unsubscribeFunctions: {
          ...state.unsubscribeFunctions,
          [collectionName]: unsubscribe,
        },
      };
    },
    [CLEAR_COLLECTIONS]: (state) => {
      return {
        ...state,
        collections: {},
      };
    },
  },
  initialState
);



// export const collectionsReducer = createReducer(
//   {
//     [ADD_DOCUMENT]: (state, payload) => {
//       const { collectionName, documents } = payload
//       console.log("reducer insight",collectionName, documents)
//       const updatedCollection = {
//         ...state.collections[collectionName],
//         ...documents.reduce((acc, doc) => {
//           console.log("docId",doc.id,doc._id)
//           acc[doc._id] = doc;
//           return acc;
//         }, {}),
//       };
//       return {
//         ...state,
//         collections: {
//           ...state.collections,
//           [collectionName]: updatedCollection,
//         },
//       };
//     },
//   },
//   initialState
// );
