import { useDispatch, useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import { useState, useEffect } from "react"
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MoreHorizontal } from 'react-feather';

import './styles2.css'
import { capitalizeFirstLetter, dateFormat } from "../../services"
import { setMobileMenu } from "../../state/actions/mobileMenu"
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
//import './styles.css'

function DynamicCollectionPage (props) {
    const dispatch = useDispatch();
    const collectionDataInState = useSelector((state) => state.collections.collections["index"]);
    const data = useSelector((state) => state.collections.collections[props.title]);
    const collectionData = collectionDataInState.find((item) => item.collectionName === props.title);
    const lastUpdated = collectionData.lastUpdated;
    const [showOptions, setShowOptions] = useState(false);
    const [selectedSortOption, setSelectedSortOption] = useState(collectionData.filterType? collectionData.filterType:'nameAsc');
    const [sortedData, setSortedData] = useState(data);
    const [initialSortOption, setInitialSortOption] = useState(selectedSortOption);
    const [initialSortedData, setInitialSortedData] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    
    
    
    
    
    const handleSave = async () => {
        if (selectedSortOption === 'manual') {
            // Update index field for manual sorting
            const updatedData = sortedData.map((item, index) => ({ ...item, index }));
            await Promise.all(updatedData.map(item => updateDoc(doc(db, "projects", item.id), { index: item.index })));
        }
    
        // Get the document ID from collectionDataInState
        const docId = collectionData.id;
    
        // Update filter type in index collection using the retrieved document ID
        await updateDoc(doc(db, "index", docId), { filterType: selectedSortOption });
        setShowOptions(false);
    };

    useEffect(() => {
        // Initialize initialSortOption and initialSortedData when component mounts
        setInitialSortOption(selectedSortOption);
    
        let initialDataCopy = [...data];
        switch (selectedSortOption) {
            case 'nameAsc':
                initialDataCopy.sort((a, b) => a.name?.data.localeCompare(b.name?.data));
                break;
            case 'nameDesc':
                initialDataCopy.sort((a, b) => b.name?.data.localeCompare(a.name?.data));
                break;
            case 'dateAsc':
                initialDataCopy.sort((a, b) => a.lastUpdated.toDate() - b.lastUpdated.toDate());
                break;
            case 'dateDesc':
                initialDataCopy.sort((a, b) => b.lastUpdated.toDate() - a.lastUpdated.toDate());
                break;
            default:
                initialDataCopy = [...data].sort((a, b) => a.index - b.index)
                break;
        }
        setInitialSortedData(initialDataCopy);
    }, [data]);
    
    // Detect unsaved changes
    useEffect(() => {
        const sortOptionChanged = selectedSortOption !== initialSortOption;
        const indexChanged = sortedData.some((item, index) => item.index !== initialSortedData[index]?.index);
        console.log(sortOptionChanged,indexChanged,sortedData, initialSortedData)
        setHasUnsavedChanges(sortOptionChanged || indexChanged);
    }, [selectedSortOption, sortedData, initialSortedData]);   
    

    useEffect(() => {
        // Update sortedData based on selectedSortOption
        if (selectedSortOption === 'manual') {
            // Sort by index for manual order
            const sortedByIndex = [...sortedData].sort((a, b) => a.index - b.index);
            setSortedData(sortedByIndex);
        } else {
            let newData = [...data];
            switch (selectedSortOption) {
                case 'nameAsc':
                    newData.sort((a, b) => a.name?.data.localeCompare(b.name?.data));
                    break;
                case 'nameDesc':
                    newData.sort((a, b) => b.name?.data.localeCompare(a.name?.data));
                    break;
                case 'dateAsc':
                    newData.sort((a, b) => a.lastUpdated.toDate() - b.lastUpdated.toDate());
                    break;
                case 'dateDesc':
                    newData.sort((a, b) => b.lastUpdated.toDate() - a.lastUpdated.toDate());
                    break;
                default:
                    // Unknown option
                    break;
            }
            setSortedData(newData);
        }
    }, [selectedSortOption]);  

    return (
        <>
            <div class="menu-projects">
                <div class="menu-projects__wrap">
                    <div class="projects__heading">
                        <div class="heading-center">
                            <div class="projects-head__flex">
                            <h1 class="h-24 text-dark">{capitalizeFirstLetter(props.title)} Database</h1>
                            </div>
                            <div class="update-flex">
                                <p>Last updated</p>
                            <div class="w-dyn-list">
                                <div role="list" class="w-dyn-items">
                                    <div role="listitem" class="w-dyn-item">
                                        <p>{dateFormat(lastUpdated)}</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div onClick={()=> dispatch(setMobileMenu(true))} data-w-id="ecbbd13b-b482-7cdf-0270-fd4d2e7454e4" style={{opacity:1, display: "flex"}} class="hamburger-wrap _3-lines">
                            <div class="hamburger-line"></div>
                            <div class="hamburger-line middle"></div>
                            <div class="hamburger-line"></div>
                        </div>
                        </div>
                        <div class="border mt-16"></div>
                        <div data-current="Tab 1" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs-home w-tabs">
                        <div class="menu-tabs">
                            <Link to={`/${props.title}/new-item`}>
                            <button
                                class="add-item-button w-button"
                                >
                                Add Item
                            </button>
                            </Link>
                        </div>
                        <div class="tabs-content-2 w-tab-content">
                            <div data-w-tab="Tab 2" class="tab-pane-tab-2 w-tab-pane w--tab-active">
                                <div class="invoices-wrapper">
                                    <div class="projects-count__wrap withSettings">
                                        <div className="projects-count__wrap other">
                                            <h2 class="h-16 text-dark">Database items</h2>
                                            <div class="count-box">
                                                <p class="upcoming-number">{data.length}</p>
                                            </div>
                                        </div>
                                        {!showOptions && (
                                            <button
                                                onClick={() => setShowOptions(!showOptions)} // Add this line
                                                class="settings-button"
                                                >
                                                <MoreHorizontal color="#989fb2"/>
                                            </button>
                                        )}
                                        {showOptions && (
                                            <div className="options-panel">
                                                <div>Sort Options</div>
                                                <select
                                                    value={selectedSortOption}
                                                    onChange={(e) => setSelectedSortOption(e.target.value)}
                                                >
                                                    <option value="manual">Manual Order</option>
                                                    <option value="nameAsc">Name A-Z</option>
                                                    <option value="nameDesc">Name Z-A</option>
                                                    <option value="dateAsc">Date First-Last</option>
                                                    <option value="dateDesc">Date Last-First</option>
                                                </select>
                                                <button onClick={() => {hasUnsavedChanges? handleSave(): setShowOptions(false)}}>
                                                    {hasUnsavedChanges? "Save":"back"}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {showOptions?
                                    <div class="w-dyn-list">
                                        {sortedData ? (
                                            <div class="invoices-list w-dyn-items">
                                                <DragDropContext
                                                    onDragEnd={result => {
                                                        if (!result.destination) {
                                                            return;
                                                        }
                                                
                                                        const items = Array.from(sortedData);
                                                        const [reorderedItem] = items.splice(result.source.index, 1);
                                                        items.splice(result.destination.index, 0, reorderedItem);
                                                
                                                        setSelectedSortOption("manual"); //automatic switch to manual
                                                        setSortedData(items);
                                                    }}
                                                >
                                                    <Droppable droppableId="droppable">
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                            >
                                                                {sortedData.map((item, index) => (
                                                                    <Draggable
                                                                        key={item.id}
                                                                        draggableId={item.id}
                                                                        index={index}
                                                                    >
                                                                        {(provided) => (
                                                                            <div class="dragItem" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                <div class="invoice-l">
                                                                                    <img loading="lazy" src="" alt="" class="invoice-icon"/>
                                                                                    <div class="invoice-info">
                                                                                        <p class="p-16 text-dark mb-5">{item.name?.data}</p>
                                                                                        <div class="inv-date-wrap">
                                                                                            <p>#</p>
                                                                                            <p>00{index + 1}</p>
                                                                                            <div class="dot"></div>
                                                                                            <p>{item.id}</p>
                                                                                            <div class="dot"></div>
                                                                                            <p>{dateFormat(item.lastUpdated)}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="invoice-r">
                                                                                    <div class="centered-copy w-embed">
                                                                                        <svg width="32" height="32" viewbox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path d="M30.804 25.3333H29.3334V20.6667C29.3334 19.5627 28.4374 18.6667 27.3334 18.6667C26.2294 18.6667 25.3334 19.5627 25.3334 20.6667V25.3333H23.8627C22.9774 25.3333 22.4774 26.348 23.0174 27.0493L26.488 31.5653C26.9147 32.1213 27.752 32.1213 28.18 31.5653L31.6507 27.0493C32.1894 26.348 31.688 25.3333 30.804 25.3333Z" fill="currentColor"></path>
                                                                                            <path opacity="0.35" d="M5.3335 25.3333V6.66666C5.3335 4.45732 7.12416 2.66666 9.3335 2.66666H18.6668L26.6668 10.6667V25.3333C26.6668 27.5427 24.8762 29.3333 22.6668 29.3333H9.3335C7.12416 29.3333 5.3335 27.5427 5.3335 25.3333Z" fill="currentColor"></path>
                                                                                            <path d="M18.6665 7.99999V2.66666L26.6665 10.6667H21.3332C19.8598 10.6667 18.6665 9.47332 18.6665 7.99999Z" fill="currentColor"></path>
                                                                                            <path d="M12.9373 18.508C12.9373 20.692 11.1707 21.084 10.5533 21.084H9.77467V23.112C9.77467 23.6027 9.37733 24 8.88667 24C8.39733 24 8 23.6027 8 23.1133V17.056C8 16.472 8.472 16 9.05467 16H10.4853C11.0853 16 12.9373 16.2267 12.9373 18.508ZM11.2427 18.5427C11.2427 17.6373 10.5987 17.5253 10.3493 17.5253H9.77467V19.616H10.3507C10.5987 19.616 11.2427 19.4467 11.2427 18.5427Z" fill="currentColor"></path>
                                                                                            <path d="M19.4613 20.0227C19.4613 23.0533 17.84 24 16.1386 24H14.9453C14.3626 24 13.8906 23.528 13.8906 22.9453V17.056C13.8906 16.4733 14.3626 16.0013 14.9453 16.0013H16.1386C17.9026 16 19.4613 16.9933 19.4613 20.0227ZM17.6413 20.0227C17.6413 17.9067 16.5973 17.5813 16.1613 17.5813H15.72V22.416H16.1613C16.5973 22.4173 17.6413 22.14 17.6413 20.0227Z" fill="currentColor"></path>
                                                                                            <path d="M22.3172 17.628V19.2653H23.4799C23.8919 19.2653 24.2252 19.5987 24.2252 20.0107V20.0667C24.2252 20.4787 23.8919 20.812 23.4799 20.812H22.3279V23.084C22.3279 23.5893 21.9185 23.9987 21.4132 23.9987C20.9079 23.9987 20.4985 23.5893 20.4985 23.084V17.0547C20.4985 16.472 20.9705 16 21.5532 16H23.5999C24.0399 16 24.3972 16.3573 24.3972 16.7973V16.832C24.3972 17.272 24.0399 17.6293 23.5999 17.6293L22.3172 17.628Z" fill="currentColor"></path>
                                                                                        </svg>
                                                                                    </div>
                                                                                    <p class="p-14 upcoming-number">&gt;</p>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        ) : (
                                            {/* ... */}
                                        )}
                                    </div>
                                    :
                                    <div class="w-dyn-list">
                                        {data? 
                                        <div role="list" class="invoices-list w-dyn-items">
                                            {sortedData.map((item, index) => (
                                                <div role="listitem"  key={index} class="w-dyn-item">
                                                <Link data-w-id="ecbbd13b-b482-7cdf-0270-fd4d2e7455f3" to={`/${props.title}/${item.id}`} class="invoice">
                                                    <div class="invoice-l">
                                                        <img loading="lazy" src="" alt="" class="invoice-icon"/>
                                                        <div class="invoice-info">
                                                            <p class="p-16 text-dark mb-5">{item.name?.data}</p>
                                                            <div class="inv-date-wrap">
                                                                <p>#</p>
                                                                <p>00{index + 1}</p>
                                                                <div class="dot"></div>
                                                                <p>{item.id}</p>
                                                                <div class="dot"></div>
                                                                <p>{dateFormat(item.lastUpdated)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                    class="invoice-r">
                                                        <div class="centered-copy w-embed">
                                                            <svg width="32" height="32" viewbox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M30.804 25.3333H29.3334V20.6667C29.3334 19.5627 28.4374 18.6667 27.3334 18.6667C26.2294 18.6667 25.3334 19.5627 25.3334 20.6667V25.3333H23.8627C22.9774 25.3333 22.4774 26.348 23.0174 27.0493L26.488 31.5653C26.9147 32.1213 27.752 32.1213 28.18 31.5653L31.6507 27.0493C32.1894 26.348 31.688 25.3333 30.804 25.3333Z" fill="currentColor"></path>
                                                                <path opacity="0.35" d="M5.3335 25.3333V6.66666C5.3335 4.45732 7.12416 2.66666 9.3335 2.66666H18.6668L26.6668 10.6667V25.3333C26.6668 27.5427 24.8762 29.3333 22.6668 29.3333H9.3335C7.12416 29.3333 5.3335 27.5427 5.3335 25.3333Z" fill="currentColor"></path>
                                                                <path d="M18.6665 7.99999V2.66666L26.6665 10.6667H21.3332C19.8598 10.6667 18.6665 9.47332 18.6665 7.99999Z" fill="currentColor"></path>
                                                                <path d="M12.9373 18.508C12.9373 20.692 11.1707 21.084 10.5533 21.084H9.77467V23.112C9.77467 23.6027 9.37733 24 8.88667 24C8.39733 24 8 23.6027 8 23.1133V17.056C8 16.472 8.472 16 9.05467 16H10.4853C11.0853 16 12.9373 16.2267 12.9373 18.508ZM11.2427 18.5427C11.2427 17.6373 10.5987 17.5253 10.3493 17.5253H9.77467V19.616H10.3507C10.5987 19.616 11.2427 19.4467 11.2427 18.5427Z" fill="currentColor"></path>
                                                                <path d="M19.4613 20.0227C19.4613 23.0533 17.84 24 16.1386 24H14.9453C14.3626 24 13.8906 23.528 13.8906 22.9453V17.056C13.8906 16.4733 14.3626 16.0013 14.9453 16.0013H16.1386C17.9026 16 19.4613 16.9933 19.4613 20.0227ZM17.6413 20.0227C17.6413 17.9067 16.5973 17.5813 16.1613 17.5813H15.72V22.416H16.1613C16.5973 22.4173 17.6413 22.14 17.6413 20.0227Z" fill="currentColor"></path>
                                                                <path d="M22.3172 17.628V19.2653H23.4799C23.8919 19.2653 24.2252 19.5987 24.2252 20.0107V20.0667C24.2252 20.4787 23.8919 20.812 23.4799 20.812H22.3279V23.084C22.3279 23.5893 21.9185 23.9987 21.4132 23.9987C20.9079 23.9987 20.4985 23.5893 20.4985 23.084V17.0547C20.4985 16.472 20.9705 16 21.5532 16H23.5999C24.0399 16 24.3972 16.3573 24.3972 16.7973V16.832C24.3972 17.272 24.0399 17.6293 23.5999 17.6293L22.3172 17.628Z" fill="currentColor"></path>
                                                            </svg>
                                                        </div>
                                                        <p class="p-14 upcoming-number">&gt;</p>
                                                    </div>
                                                </Link>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div class="empty-state w-dyn-empty">
                                            <div>There are currently no items in this database.<br/></div>
                                        </div>
                                        }
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                       
        </>
    )
} 

export default DynamicCollectionPage