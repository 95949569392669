import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { signIn } from '../../state/actions/auth';
import { Navigate } from 'react-router-dom';
import paths from '../Router/paths';

function LoginPage() {
    const dispatch = useDispatch();
    const { error, isAuth, loading } = useSelector(
        (state) => ({
            error: state.auth.error,
            isAuth: !!state.auth.userData.id,
            loading: state.auth.loading,
        }),
        shallowEqual
    );

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        overflow: 'hidden',
    };

    function handleSignIn(event) {
        event.preventDefault(); // Prevent form submission to perform custom handling

        // Retrieve email and password values
        const email = document.getElementById("Login-form-Email-Address").value;
        const password = document.getElementById("field").value;

        // Check if password meets the minimum length requirement
        if (password.length < 8) {
            console.error("Password must be at least 8 characters long.");
            return;
        }

        console.log('Attempting to sign in with email:', email);

        // Call the handleSignIn function with the email and password values
        dispatch(signIn(email, password));
    }

    const redirect = isAuth && <Navigate to={paths.ROOT} />;

    return (
        <div style={containerStyle}>
            <div className="section secondary-background wf-section">
                {redirect}
                <div className="container w-container">
                    <img src="logo1330.png" loading="lazy" alt="logo" className="centered-logo" />
                    <div className="centered-form simple-shadow-small w-form">
                        <div className="form-heading">Login</div>
                        <form
                            id="wf-form-Login-form"
                            name="wf-form-Login-form"
                            data-name="Login form"
                            onSubmit={(event) => handleSignIn(event)}
                        >
                            <label htmlFor="Login-form-Email-Address">Email Address</label>
                            <input
                                type="email"
                                className="input w-input"
                                autoFocus={true}
                                maxLength="256"
                                name="Login-form-Email-Address"
                                data-name="Login form Email Address"
                                placeholder="Enter Your Email Address"
                                id="Login-form-Email-Address"
                                required=""
                            />
                            <label htmlFor="Password-3">Password</label>
                            <input
                                type="password"
                                className="input w-input"
                                maxLength="256"
                                name="Password-2"
                                data-name="Password 2"
                                placeholder="Enter your Password"
                                id="field"
                                required=""
                            />
                            <input
                                type="submit"
                                value="Submit"
                                data-wait="Please wait..."
                                className="button2 full-width w-button"
                            />
                        </form>
                        <div className="form-success w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div className="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                    <a href="index.html#" className="centered-text-link">
                        Forgot your password?
                    </a>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
