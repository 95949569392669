import {signInWithEmailAndPassword, signOut, onAuthStateChanged} from  "firebase/auth"
import { toastr } from 'react-redux-toastr';
import { createAction } from "redux-act";

import { auth } from "../../firebase";
import { fetchDocument } from "../../services/firestore";


export const AUTH_SIGN_IN_INIT = createAction('AUTH_SIGN_IN_INIT');
export const AUTH_SIGN_IN_FAIL = createAction('AUTH_SIGN_IN_FAIL');

export const AUTH_RESTORE_SESSION_INIT = createAction(
  'AUTH_RESTORE_SESSION_INIT'
);
export const AUTH_RESTORE_SESSION_SUCCESS = createAction(
  'AUTH_RESTORE_SESSION_SUCCESS'
);
export const AUTH_RESTORE_SESSION_FAIL = createAction(
  'AUTH_RESTORE_SESSION_FAIL'
);

export const AUTH_LOGOUT_INIT = createAction('AUTH_LOGOUT_INIT');
export const AUTH_LOGOUT_SUCCESS = createAction('AUTH_LOGOUT_SUCCESS');

export const AUTH_SET_PASSWORD_INIT = createAction('AUTH_SET_PASSWORD_INIT');
export const AUTH_SET_PASSWORD_SUCCESS = createAction(
  'AUTH_SET_PASSWORD_SUCCESS'
);
export const AUTH_SET_PASSWORD_FAIL = createAction('AUTH_SET_PASSWORD_FAIL');

export const AUTH_RESET_PASSWORD_INIT = createAction(
  'AUTH_RESET_PASSWORD_INIT'
);
export const AUTH_RESET_PASSWORD_SUCCESS = createAction(
  'AUTH_RESET_PASSWORD_SUCCESS'
);
export const AUTH_RESET_PASSWORD_FAIL = createAction(
  'AUTH_RESET_PASSWORD_FAIL'
);

export const AUTH_CLEAN_UP = createAction('AUTH_CLEAN_UP');

export const AUTH_FETCH_USER_DATA_INIT = createAction(
  'AUTH_FETCH_USER_DATA_INIT'
);
export const AUTH_FETCH_USER_DATA_SUCCESS = createAction(
  'AUTH_FETCH_USER_DATA_SUCCESS'
);
export const AUTH_FETCH_USER_DATA_FAIL = createAction(
  'AUTH_FETCH_USER_DATA_FAIL'
);

export const AUTH_CHANGE_PASSWORD_INIT = createAction(
  'AUTH_CHANGE_PASSWORD_INIT'
);
export const AUTH_CHANGE_PASSWORD_SUCCESS = createAction(
  'AUTH_CHANGE_PASSWORD_SUCCESS'
);
export const AUTH_CHANGE_PASSWORD_FAIL = createAction(
  'AUTH_CHANGE_PASSWORD_FAIL'
);

export const AUTH_UPDATE_SHIPMENT_DATA = createAction('AUTH_UPDATE_SHIPMENT_DATA');

export const AUTH_UPDATE_USER_DATA = createAction('AUTH_UPDATE_USER_DATA');

export const AUTH_PROVIDER_INIT = createAction('AUTH_PROVIDER_INIT');

export const AUTH_PROVIDER_SUCCESS = createAction('AUTH_PROVIDER_SUCCESS');

export const AUTH_PROVIDER_FAIL = createAction('AUTH_PROVIDER_FAIL');


export const verifyAuth = () => {
    return (dispatch) => {
      onAuthStateChanged(auth,(user) => {
        dispatch(AUTH_RESTORE_SESSION_INIT());
  
        if (user !== null) {
          return dispatch(AUTH_RESTORE_SESSION_SUCCESS());
        }
  
        dispatch(AUTH_RESTORE_SESSION_FAIL());
        return dispatch(logout());
      });
    };
  };


export const signIn = (email, password) => {
    return (dispatch) => {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            // ...
            return dispatch(fetchUserData());
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            toastr.error(errorCode, errorMessage);
        });
    }
};

export const logout = () => {
    return async (dispatch) => {
        dispatch(AUTH_LOGOUT_INIT());
       // dispatch(clearUsersDataLogout());
        signOut(auth).then(() => {
            dispatch(AUTH_LOGOUT_SUCCESS());
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            toastr.error(errorCode, errorMessage);
        });
    }
};
  
export const fetchUserData = () => {
    return async (dispatch) => {
        //dispatch(AUTH_FETCH_USER_DATA_INIT());
        const { uid } = auth.currentUser;
        let user;
    
        try {
            user = await fetchDocument('users', uid);
        } catch (error) {
            dispatch(logout());
            return dispatch(AUTH_FETCH_USER_DATA_FAIL({ error }));
        }
    
        if (!user) {
            return dispatch(logout());
        }
    
        return dispatch(
            AUTH_FETCH_USER_DATA_SUCCESS({
            id: uid,
            ...user,
            })
        );
    };
  };