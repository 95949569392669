import { doc, addDoc, getDoc, updateDoc } from "firebase/firestore"; 
import { db } from "../firebase";


export const fetchDocument = async (collection, id) => {
    const docSnap = await getDoc(doc(db, collection, id));
    console.log("called")
    if (!docSnap.exists()) {
        return null;
    }
    console.log("Document data:", docSnap.data());
    return { id: docSnap.id, ...docSnap.data()};
  };

export const createDocument = async(collection, values) =>{
    await addDoc(doc(db, collection), {values});
}

export const updateDocument = async(collection, id, values) =>{
    await updateDoc(doc(db, collection, id), {values});
}

