import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';


import { authReducer } from './auth';
//import { usersReducer } from './users';
import { preferencesReducer } from './preferences';
import { collectionsReducer } from './collections';
import { mobileMenuReducer } from './mobileMenu';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  collections: persistReducer(
    {
      key: 'collections',
      storage,
      blacklist: ['error', 'loading'],
    },
    collectionsReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  mobileMenu: persistReducer(
    { key: 'mobileMenu', storage },
    mobileMenuReducer
  ),
  // users: persistReducer(
  //   {
  //     key: 'users',
  //     storage,
  //     blacklist: ['error', 'loading'],
  //   },
  //   usersReducer
  // ),
  toastr: toastrReducer,
});
