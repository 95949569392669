import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'

import { logout } from '../../state/actions/auth'
import { capitalizeFirstLetter } from '../../services'
import { setMobileMenu } from '../../state/actions/mobileMenu'

function Nav() {
    const location = useLocation();
    const currentPage = location.pathname;
    const dispatch = useDispatch()
    const handleSignOut = () =>{
        dispatch(logout())
    }
    const state = useSelector((state) => state)
    const [dropdown, setDropdown] = useState(false)
    const data = useSelector((state) => state.collections.collections);
    const collections = Object.keys(data).filter((collectionName) => collectionName !== 'index');

    useEffect(() => {
        dispatch(setMobileMenu(false))
    }, [currentPage])
    

    function replaceSpacesWithPercent20(inputString) {
        if (typeof inputString !== "string") {
          throw new Error("Input must be a string.");
        }
        // Using a regular expression to replace all spaces with "%20"
        const resultString = inputString.replace(/ /g, "%20");
        return resultString;
    }

  return (
    <div class={state.mobileMenu.open? "menu-left open":"menu-left"}>
                <div class="menu-l__wrap">
                    <div class="logo-wrap homepage">
                        <a href="#" class="nav_logo-link w-nav-brand" style={{display:"flex", alignItems: 'center', justifyContent:"center", flexDirection:"row"}}>
                            <img src="logo1330.png" loading="lazy" alt="" class="nav_logo"/>
                        </a>
                        <div onClick={() => dispatch(setMobileMenu(false))} data-w-id="d62fde6e-69bc-48c6-499d-0ccba3d364e2" class="hamburger-wrap x">
                            <div class="hamburger-line top"></div>
                            <div class="hamburger-line bottom"></div>
                        </div>
                    </div>
                    <div class="menu-l__links">
                        <h2 class="menu-l__h">Menu</h2>
                        <Link onPress={() => dispatch(setMobileMenu(false))} to={"/"}  className={currentPage == "/"? "menu-link w--current":"menu-link"}>
                            <div class="centered w-embed">
                                <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.35" d="M18 21H6C4.343 21 3 19.657 3 18V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V18C21 19.657 19.657 21 18 21Z" fill="currentColor"></path>
                                    <path d="M10 18H7C6.448 18 6 17.552 6 17V14C6 13.448 6.448 13 7 13H10C10.552 13 11 13.448 11 14V17C11 17.552 10.552 18 10 18Z" fill="currentColor"></path>
                                    <path d="M17 18H14C13.448 18 13 17.552 13 17V14C13 13.448 13.448 13 14 13H17C17.552 13 18 13.448 18 14V17C18 17.552 17.552 18 17 18Z" fill="currentColor"></path>
                                    <path d="M10 11H7C6.448 11 6 10.552 6 10V7C6 6.448 6.448 6 7 6H10C10.552 6 11 6.448 11 7V10C11 10.552 10.552 11 10 11Z" fill="currentColor"></path>
                                    <path d="M17 11H14C13.448 11 13 10.552 13 10V7C13 6.448 13.448 6 14 6H17C17.552 6 18 6.448 18 7V10C18 10.552 17.552 11 17 11Z" fill="currentColor"></path>
                                </svg>
                            </div>
                            <p className="upcoming-number">Dashboard</p>
                        </Link>
                        <div data-hover="false" data-delay="0" class="dd-nav w-dropdown">
                            <div onClick={()=> setDropdown(!dropdown)} class="dd-toggle text-light ">
                                <div class="dd-toggle-flex">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.501 8H20.5C21.068 8 21.584 8.19 22 8.504V8C22 6.343 20.657 5 19 5H11L10.456 3.368C10.184 2.551 9.419 2 8.558 2H4C2.895 2 2 2.895 2 4V8.504C2.416 8.19 2.932 8 3.501 8Z" fill="#989FB2"/>
                                        <path opacity="0.35" d="M20.499 8H3.50101C1.95601 8 0.78101 9.387 1.03501 10.911L2.13201 17.493C2.37301 18.94 3.62401 20 5.09101 20H18.909C20.376 20 21.627 18.94 21.868 17.493L22.965 10.911C23.219 9.387 22.044 8 20.499 8Z" fill="#989FB2"/>
                                    </svg>
                                    <div class="dd-text">Databases</div>
                                </div>
                                <div class="centered w-embed">
                                    <svg width="11" height="11" viewbox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.7443 3.9918C1.77059 3.92924 1.81482 3.87586 1.87141 3.83839C1.92799 3.80092 1.9944 3.78104 2.06226 3.78125L8.93726 3.78125C9.00513 3.78104 9.07154 3.80092 9.12812 3.83839C9.18471 3.87586 9.22894 3.92924 9.25523 3.9918C9.28 4.05529 9.28628 4.1245 9.27336 4.19142C9.26043 4.25833 9.22882 4.32022 9.18219 4.36992L5.74469 7.80742C5.67898 7.87096 5.59116 7.90647 5.49977 7.90647C5.40837 7.90647 5.32055 7.87096 5.25484 7.80742L1.81734 4.36992C1.77071 4.32022 1.7391 4.25833 1.72617 4.19142C1.71325 4.1245 1.71953 4.05529 1.7443 3.9918Z" fill="currentColor"></path>
                                    </svg>
                                </div>
                            </div>
                            <nav class={dropdown? 'doc-dd__list w-dropdown-list w--open':'doc-dd__list w-dropdown-list'}>
                                <div class="w-dyn-list">
                                    {collections?
                                    <div role="list" class="w-dyn-items">
                                        {collections?.map((fieldName, index) => (                                        
                                        <div role="listitem" key={index} class="w-dyn-item">
                                            <Link to={`/${fieldName}`} className={currentPage == "/"+replaceSpacesWithPercent20(fieldName)? "menu-link__dd w--current":"menu-link__dd"}>
                                                <div class="centered w-embed">
                                                    <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path opacity="0.35" d="M5 18V6C5 4.343 6.343 3 8 3H13L19 9V18C19 19.657 17.657 21 16 21H8C6.343 21 5 19.657 5 18Z" fill="currentColor"></path>
                                                        <path d="M13 7V3L19 9H15C13.895 9 13 8.105 13 7Z" fill="currentColor"></path>
                                                        <path d="M15 13H9C8.447 13 8 12.552 8 12C8 11.448 8.447 11 9 11H15C15.553 11 16 11.448 16 12C16 12.552 15.553 13 15 13Z" fill="currentColor"></path>
                                                        <path d="M13 17H9C8.447 17 8 16.552 8 16C8 15.448 8.447 15 9 15H13C13.553 15 14 15.448 14 16C14 16.552 13.553 17 13 17Z" fill="currentColor"></path>
                                                    </svg>
                                                </div>
                                                <p class="doc-p">{capitalizeFirstLetter(fieldName)}</p>
                                            </Link>
                                        </div>
                                        ))}
                                    </div>
                                    :
                                    <div class="w-dyn-empty">
                                        <div>No items found.</div>
                                    </div>
                                    }
                                </div>
                            </nav>
                        </div>
                        <Link to={'/settings'} className={currentPage == "/settings"? "menu-link w--current":"menu-link"}>
                            <div class="centered w-embed">
                                <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 20V12.816C19.686 12.928 19.353 13 19 13C18.647 13 18.314 12.928 18 12.816V20C18 20.552 18.448 21 19 21C19.552 21 20 20.552 20 20Z" fill="currentColor"></path>
                                    <path d="M18 4V7.184C18.314 7.072 18.647 7 19 7C19.353 7 19.686 7.072 20 7.184V4C20 3.448 19.552 3 19 3C18.448 3 18 3.448 18 4Z" fill="currentColor"></path>
                                    <path opacity="0.35" d="M19 13C20.6569 13 22 11.6569 22 10C22 8.34315 20.6569 7 19 7C17.3431 7 16 8.34315 16 10C16 11.6569 17.3431 13 19 13Z" fill="currentColor"></path>
                                    <path d="M13 20V17.816C12.686 17.928 12.353 18 12 18C11.647 18 11.314 17.928 11 17.816V20C11 20.552 11.448 21 12 21C12.552 21 13 20.552 13 20Z" fill="currentColor"></path>
                                    <path d="M11 4V12.184C11.314 12.072 11.647 12 12 12C12.353 12 12.686 12.072 13 12.184V4C13 3.448 12.552 3 12 3C11.448 3 11 3.448 11 4Z" fill="currentColor"></path>
                                    <path opacity="0.35" d="M12 18C13.6569 18 15 16.6569 15 15C15 13.3431 13.6569 12 12 12C10.3431 12 9 13.3431 9 15C9 16.6569 10.3431 18 12 18Z" fill="currentColor"></path>
                                    <path d="M6 20V12.816C5.686 12.928 5.353 13 5 13C4.647 13 4.314 12.928 4 12.816V20C4 20.552 4.448 21 5 21C5.552 21 6 20.552 6 20Z" fill="currentColor"></path>
                                    <path d="M4 4V7.184C4.314 7.072 4.647 7 5 7C5.353 7 5.686 7.072 6 7.184V4C6 3.448 5.552 3 5 3C4.448 3 4 3.448 4 4Z" fill="currentColor"></path>
                                    <path opacity="0.35" d="M5 13C6.65685 13 8 11.6569 8 10C8 8.34315 6.65685 7 5 7C3.34315 7 2 8.34315 2 10C2 11.6569 3.34315 13 5 13Z" fill="currentColor"></path>
                                </svg>
                            </div>
                            <p className="upcoming-number">Settings</p>
                        </Link>
                    </div>
                </div>
                <a onClick={() => handleSignOut()} class="logout">
                    <div class="centered w-embed">
                        <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.35" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor"></path>
                            <path d="M17 16.131C17 16.902 17.93 17.29 18.478 16.747L22.371 12.891C22.866 12.4 22.866 11.6 22.371 11.109L18.478 7.253C17.93 6.71 17 7.098 17 7.869V16.131Z" fill="currentColor"></path>
                            <path d="M19.799 10H12V14H19.799V10Z" fill="currentColor"></path>
                            <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="currentColor"></path>
                        </svg>
                    </div>
                    <p className="upcoming-number">Log Out</p>
                </a>
            </div>
  )
}

export default Nav