import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'

import HomePage from '../home';
import LoginPage from '../login';

import paths from './paths';
import PrivateRoute from './PrivateRoute';
import { auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { fetchDocuments } from '../../state/actions/collections';
import { store } from '../..';
import DynamicCollectionPage from '../dynamicCollection';
import SettingsPage from '../settings';
import DynamicItemPage from '../dynamicItem';
import NewItemPage from '../newItem';
import NewCollectionPage from '../newCollection';

const RouterComponent = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchDocuments(dispatch);
      } else {
        // Clear the collections in the state when logging out or session expires
        dispatch({ type: 'CLEAR_COLLECTIONS' });
      }
    });

    return () => {
      // Unsubscribe from the auth state changes and all collection listeners
      unsubscribeAuth();
      // Retrieve the saved unsubscribe functions and call them
      const state = store.getState();
      Object.values(state.collections.unsubscribeFunctions).forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, [dispatch]);
  const data = useSelector((state) => (state.collections.collections))
  const collections = Object.keys(data)
  return (
    <BrowserRouter>
        <Routes>
          <Route exact path='/login' element={<LoginPage/>} />
          <Route exact path='/' element={<PrivateRoute/>}>
            <Route exact path='/' element={<HomePage/>}/>
            {collections.map((collection, index) => (
              <Route key={index} path={collection} element={<DynamicCollectionPage title={collection}/>} exact />
            ))}
            <Route path="/:parentCollectionName/:id" element={<DynamicItemPage/>} />
            <Route path="/:parentCollectionName/:new-item" element={<NewItemPage/>} />
            <Route path="/new-collection" element={<NewCollectionPage/>} />
            <Route exact path='/settings' element={<SettingsPage/>}/>
          </Route>
        </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;